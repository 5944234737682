@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  padding: var(--hpgoals-padding);
  margin: var(--hpgoals-margin);
  background: var(--hpgoals-background);

  @include respond-to(sm, up) {
    padding: var(--hpgoals-padding-m, var(--hpgoals-padding));
    margin: var(--hpgoals-margin-m, var(--hpgoals-margin));
  }

  @include respond-to(lg, up) {
    padding: var(--hpgoals-padding-l, var(--hpgoals-padding));
    margin: var(--hpgoals-margin-l, var(--hpgoals-margin));
  }
}

.list {
  padding: var(--hpgoals-content-padding);
  margin: var(--hpgoals-content-margin);
  background: var(--hpgoals-content-background);
  border: var(--hpgoals-content-border);
  border-radius: var(--hpgoals-content-border-radius);
  box-shadow: (--hpgoals-content-box-shadow);

  @include respond-to(sm, up) {
    padding: var(--hpgoals-content-padding-m, var(--hpgoals-content-padding));
    margin: var(--hpgoals-content-margin-m, var(--hpgoals-content-margin));
  }

  @include respond-to(lg, up) {
    padding: var(--hpgoals-content-padding-l, var(--hpgoals-content-padding));
    margin: var(--hpgoals-content-margin-l, var(--hpgoals-content-margin));
  }
}

.goal {
  display: flex;
  padding: var(--goal-row-padding);
  margin: var(--goal-row-margin);
  background: var(--goal-row-background);
  border-bottom: var(--goal-row-border-bottom);
  border-radius: var(--goal-row-border-radius);
  box-shadow: var(--goal-row-box-shadow);
  flex-direction: var(--goal-row-flex-direction, column);
  gap: var(--goal-row-gap);

  @include respond-to(sm, up) {
    padding: var(--goal-row-padding-m);
    flex-direction: var(--goal-row-flex-direction-m, row);
  }

  @include respond-to(lg, up) {
    padding: var(--goal-row-padding-l);
    flex-direction: var(--goal-row-flex-direction-l, row);
  }
}

.header {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  gap: var(--goal-row-text-gap);
  padding: var(--goal-row-text-padding);

  @include respond-to(sm, up) {
    gap: var(--goal-row-text-gap-m);
    padding: var(--goal-row-text-padding-m);
  }

  @include respond-to(lg, up) {
    flex: 2;
    gap: var(--goal-row-text-gap-l);
    padding: var(--goal-row-text-padding-l);
  }
}

.heading {
  @include with-styled-text(goal-row-heading);
  margin: var(--goal-row-heading-margin);

  @include respond-to(sm, up) {
    font-size: var(--goal-row-heading-font-size-m, var(--goal-row-heading-font-size));
    margin: var(--goal-row-heading-margin-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--goal-row-heading-font-size-l, var(--goal-row-heading-font-size));
    margin: var(--goal-row-heading-margin-l);
  }
}

.subHeading {
  @include with-styled-text(goal-row-sub-heading);
}

.button {
  padding: var(--hpgoals-button-container-padding);

  @include respond-to(sm, up) {
    padding: var(--hpgoals-button-container-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--hpgoals-button-container-padding-l);
  }
}

.details {
  flex: 0 1;
  display: flex;
  align-items: center;
  justify-content: var(--goal-row-detail-justify-content);
  gap: var(--goal-row-detail-gap);
  padding: var(--goal-row-detail-padding);

  @include respond-to(sm, up) {
    gap: var(--goal-row-detail-gap-m, var(--goal-row-detail-gap));
    justify-content: var(--goal-row-detail-justify-content-m);
  }

  @include respond-to(lg, up) {
    gap: var(--goal-row-detail-gap-l, var(--goal-row-detail-gap));
    justify-content: var(--goal-row-detail-justify-content-l);
  }
}

.score {
  width: var(--hpgoals-score-width, 100%);
  padding: var(--goal-row-score-padding);
  gap: var(--goal-row-score-gap);
  flex: 0;
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--goal-row-action-padding);
  gap: var(--goal-row-action-gap);
}

.guidance,
.guidance:focus,
.guidance:active,
.guidance:hover,
.guidance:visited {
  flex: 1;
  width: max-content;
}

.hidden-badge {
  visibility: hidden;
}

.badge {
  flex: 0 1 var(--goal-row-badge-width, 36px);
  display: var(--goal-row-badge-display);
  align-items: center;
  justify-content: center;
  width: var(--goal-row-badge-width);

  background: var(--goal-row-badge-off-background);
  border: var(--goal-row-badge-border);
  box-shadow: var(--goal-row-badge-box-shadow);
  border-radius: var(--goal-row-badge-border-radius);
  padding: var(--goal-row-badge-padding);

  @include respond-to(sm, up) {
    padding: var(--goal-row-badge-padding-m, var(--goal-row-badge-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--goal-row-badge-padding-l, var(--goal-row-badge-padding));
  }
}

.completed-badge {
  background: var(--goal-row-badge-on-background);
}

.badgeText {
  @include with-styled-text(goal-row-badge-label);
  min-width: 17px;
}

.priority {
  height: var(--goal-row-lp-container-height);
  width: var(--goal-row-lp-container-width);
  padding: var(--goal-row-lp-container-padding);
  background: var(--goal-row-lp-container-background);
  border: var(--goal-row-lp-container-border);
  border-radius: var(--goal-row-lp-container-border-radius);
  box-shadow: var(--goal-row-lp-container-box-shadow);

  @include with-styled-text(goal-row-lp-label);
}

.goal-row-header {
  background: var(--grh-background);
  border-bottom: var(--grh-border-bottom);
  border-radius: var(--grh-border-radius);
  box-shadow: var(--grh-box-shadow);

  display: flex;
  flex-direction: var(--grh-flex-direction);
  justify-content: var(--grh-justify-content);
  align-items: var(--grh-align-items);
  gap: var(--grh-gap);
  padding: var(--grh-padding);
  margin: var(--grh-margin);

  @include respond-to(sm, up) {
    flex-direction: var(--grh-flex-direction-m);
    justify-content: var(--grh-justify-content-m);
    align-items: var(--grh-align-items-m);
    gap: var(--grh-gap-m);
    padding: var(--grh-padding-m);
    margin: var(--grh-margin-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--grh-flex-direction-l);
    justify-content: var(--grh-justify-content-l);
    align-items: var(--grh-align-items-l);
    gap: var(--grh-gap-l);
    padding: var(--grh-padding-l);
    margin: var(--grh-margin-l);
  }
}

.goal-row-header-text {
  display: flex;
  align-items: center;
  flex: 1;
}

.goal-row-header-detail {
  display: flex;
  justify-content: var(--grh-detail-justify-content, space-between);
  flex-basis: var(--grh-detail-width);

  @include respond-to(sm, up) {
    flex-basis: var(--grh-detail-width-m);
  }

  @include respond-to(lg, up) {
    flex-basis: var(--grh-detail-width-l);
  }
}

.goal-row-header-score {
  display: flex;
  align-items: center;
  gap: var(--grh-label-containers-gap);
}

.goal-row-header-action {
  display: flex;
  align-items: center;
  gap: var(--grh-label-containers-gap);
}

.goal-row-header-label {
  @include with-styled-text(grh-label);
}

.action-score-icon {
  svg {
    width: var(--grh-icon-size);
    height: var(--grh-icon-size);

    path {
      fill: var(--grh-icon-color);
    }
  }
}
