@import '../resources/vars';
@import '../resources/mixins';

.item {
  align-items: var(--tl-item-align-items);
  background: var(--tl-item-background);
  border-color: var(--tl-item-border-color);
  border-radius: var(--tl-item-border-radius);
  border-style: var(--tl-item-border-style);
  border-width: var(--tl-item-border-width);
  box-shadow: var(--tl-item-box-shadow);
  display: flex;
  flex-direction: var(--tl-item-direction);
  justify-content: var(--tl-item-justify-content);
  margin: var(--tl-item-margin);
  padding: var(--tl-item-padding);
  cursor: pointer;
  gap: var(--tl-item-content-container-gap);

  @include respond-to(sm, up) {
    gap: var(--tl-item-content-container-gap-m);
  }

  @include respond-to(lg, up) {
    gap: var(--tl-item-content-container-gap-l);
  }
}

.label-container {
  gap: var(--tl-item-label-container-gap);

  @include respond-to(sm, up) {
    gap: var(--tl-item-label-container-gap-m);
  }

  @include respond-to(lg, up) {
    gap: var(--tl-item-label-container-gap-l);
  }
}

.label {
  @include with-styled-text(tl-item-label);
}

.sub-title {
  @include with-styled-text(tl-item-sub-title);
}

.value {
  @include with-styled-text(tl-item-value);
}

.badge-container {
  background: var(--tl-item-badge-background);
  border: var(--tl-item-badge-border);
  border-radius: var(--tl-item-badge-border-radius);
  padding: var(--tl-item-badge-padding);
}

.badge {
  color: var(--tl-item-badge-label-color);
  @include with-styled-text(tl-item-badge-label);
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.icon {
  background-color: var(--tl-item-icon-bg-colour);
  border-radius: var(--tl-item-icon-border-radius);
  height: var(--tl-item-icon-size);
  margin: var(--tl-item-icon-margin);
  padding: var(--tl-item-icon-padding);
  width: var(--tl-item-icon-size);

  svg {
    height: var(--tl-item-icon-size);
    width: var(--tl-item-icon-size);
  }

  path {
    fill: var(--tl-item-icon-colour);
  }
}

.grow {
  flex: 1;
}
